@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Heebo';
  color:#242847;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.bgoverlay {

  z-index: -1;
  background-color: #fff;
  background-image: radial-gradient(at 44% 0%,rgb(122 255 126 / 40%) 0px,transparent 50%),radial-gradient(at 80% 0%,#ff1f1f69 0px,transparent 50%),radial-gradient(at 80% 50%,rgb(255 141 0 / 55%) 0px,#e70c0c00 50%),radial-gradient(at 0% 100%,rgba(255,181,138,0) 0px,transparent 50%),radial-gradient(at 80% 100%,rgb(102 227 255 / 52%) 0px,transparent 50%),radial-gradient(at 0% 0%,rgb(133 182 255 / 52%) 0px,#9913ff47 50%)
}
.bgoverlay-albury {

  z-index: -1;
  background-color: #fff;
  background-image: url("https://i.imgur.com/VpeBBY0.png");
  /* background-position: 0px -300px; */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
